import React, {useEffect, useState} from 'react';
import './App.css';

// concorrencia
// https://vurraro.art.br/produto/retrato-personalizado/

function App() {

  const [dimensionBlock, setDimensionBlock] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const resize = () => {
    setDimensionBlock({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  const scrollToPage = (page) => {
    const {height} = dimensionBlock;
    window.scroll({
      top: height * page,
      left: 0,
      behavior: "smooth",
    });
  }

  const scrollToSelector = (elementId) => {
    document.querySelector(elementId).scrollIntoView({
      behavior: "smooth",
    });
  }

  const blockScreenNavigator = () => {
    const {width, height} = dimensionBlock;
    return {
      width: width - 20,
      height: height,
      // backgroundColor: '#eeeeee',
      borderBottom: '1px solid #cccccc',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }

  useEffect(() => {
    console.log('Oi');
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  },[]);

  return (
    <div>

      <div style={blockScreenNavigator()}>
        <div className="question">
          <h1 className="titleStart">Vamos começar?</h1>
        </div>
        <button type="button" className="goDown" onClick={() => scrollToSelector('#paint_type')}>
          Próximo
        </button>
      </div>

      <h1 className="intervalInterval">Pintura</h1>

      <div id="paint_type" style={blockScreenNavigator()}>
        {/* page 2 */}
        {/* <button type="button" className="goUp" onClick={() => scrollToPage(1)}>
          Voltar
        </button> */}
        <div className="question">
          <div className="littleTitle">Pintura</div>
          Selecione o <span className="highligth">tipo de pintura</span>
        </div>
        <div className="selection">          
          <div style={{...styles.selectionBox, width: 140}}>
            Pet {'<3'}
          </div>
          <div style={{...styles.selectionBox, width: 140}}>
            Pet + Você
          </div>
          <div style={{...styles.selectionBox, ...styles.selectedBox, width: 140}}>
            Retrato
          </div>
          <div style={{...styles.selectionBox, width: 140}}>
            Casal
          </div>
          <div style={{...styles.selectionBox, width: 140}}>
            Amigos (3-4)
          </div>
          <div style={{...styles.selectionBox,...styles.removeSpacingBox, width: 140}}>
            Família (5-6)
          </div>
        </div>
        <button type="button" className="goDown" onClick={() => scrollToSelector('#paint_style')}>
          Próximo
        </button>
      </div>

      <div id="paint_style" style={blockScreenNavigator()}>
        {/* page 4 */}
        {/* <button type="button" className="goUp" onClick={() => scrollToPage(3)}>
          Voltar
        </button> */}
        <div className="question">
          <div className="littleTitle">Pintura</div>
          Selecione o <span className="highlight">estilo</span>
        </div>
        <div className="selection">          
          <div style={{...styles.selectionBox, ...styles.selectedBox}}>
            Retrato realista
          </div>
        </div>
        <button type="button" className="goDown" onClick={() => scrollToSelector('#paint_color')}>
          Próximo
        </button>
      </div>

      <div id="paint_color" style={blockScreenNavigator()}>
        {/* page 1 */}
        {/* <button type="button" className="goUp" onClick={() => scrollToPage(0)}>
          Voltar
        </button> */}
        <div className="question">
          <div className="littleTitle">Pintura</div>
          Selecione a <span className="highlight">coloração</span>
        </div>
        <div className="selection">
          <div style={{...styles.selectionBox, ...styles.selectedBox, height: 240, width: 200}}>
            Preto e Branco
          </div>
          <div style={{...styles.selectionBox,...styles.removeSpacingBox, height: 240, width: 200}}>
            Colorido
          </div>
        </div>
        <button type="button" className="goDown" onClick={() => scrollToSelector('#detail_background')}>
          Próximo
        </button>
      </div>

      <h1 className="intervalInterval">Detalhes</h1>

      <div id="detail_background" style={blockScreenNavigator()}>
        {/* page 5 */}
        {/* <button type="button" className="goUp" onClick={() => scrollToPage(3)}>
          Voltar
        </button> */}
        <div className="question">
          <div className="littleTitle">Detalhes</div>
          Selecione o <span className="highlight">fundo</span>
        </div>
        <div className="selection">          
          <div style={{...styles.selectionBox, ...styles.selectedBox}}>
            Opção 1
          </div>
          <div style={{...styles.selectionBox}}>
            Opção 2
          </div>
          <div style={{...styles.selectionBox}}>
            Opção 3
          </div>
          <div style={{...styles.selectionBox}}>
            Outro
          </div>
        </div>
        <button type="button" className="goDown" onClick={() => scrollToSelector('#detail_border')}>
          Próximo
        </button>
      </div>

      <div id="detail_border" style={blockScreenNavigator()}>
        {/* page 6 */}
        {/* <button type="button" className="goUp" onClick={() => scrollToPage(3)}>
          Voltar
        </button> */}
        <div className="question">
          <div className="littleTitle">Detalhes</div>
          Selecione o <span className="highlight">acabamento</span>
        </div>
        <div className="selection">          
        <div style={{...styles.selectionBox, ...styles.selectedBox}}>
            Completo
          </div>
          <div style={{...styles.selectionBox}}>
            Pincelada
          </div>
          <div style={{...styles.selectionBox}}>
            Pincelada + Traços
          </div>
          <div style={{...styles.selectionBox}}>
            Arestas 
          </div>
        </div>
        <button type="button" className="goDown" onClick={() => scrollToSelector('#material_print')}>
          Próximo
        </button>
      </div>

      <h1 className="intervalInterval">Finalização</h1>

      <div id="material_print" style={blockScreenNavigator()}>
        {/* page 7 */}
        {/* <button type="button" className="goUp" onClick={() => scrollToPage(3)}>
          Voltar
        </button> */}
        <div className="question">
          <div className="littleTitle">Finalização</div>
          Selecione o <span className="highlight">material</span>
        </div>
        <div className="selection">          
          <div style={{...styles.selectionBox, ...styles.selectedBox}}>
            Digital
          </div>
          <div style={{...styles.selectionBox}}>
            Tela Tecido
          </div>
          <div style={{...styles.selectionBox, ...styles.removeSpacingBox}}>
            Papel Poster
          </div>
        </div>
        <div className="description">
          <span className="highlight">Sua seleção: </span> 
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed condimentum risus, eu maximus urna. In a vestibulum est. Duis sapien risus, suscipit vel lacus sed, interdum hendrerit metus. 
        </div>
        <button type="button" className="goDown" onClick={() => scrollToSelector('#material_canva')}>
          Próximo
        </button>
      </div>

      {/* <div style={blockScreenNavigator()}>
        <button type="button" className="goUp" onClick={() => scrollToPage(2)}>
          Voltar
        </button>
        <div className="question">
          <div className="littleTitle">Pintura</div>
          Selecione o <span className="highlight">tamanho</span>
        </div>
        <div className="selection">          
          <div style={{...styles.selectionBox, ...styles.selectedBox}}>
            42x60 cm (A2)
          </div>
        </div>
        <button type="button" className="goDown" onClick={() => scrollToPage(4)}>
          Próximo
        </button>
      </div> */}

      <div id="material_canva" style={blockScreenNavigator()}>
        {/* page 8 */}
        {/* <button type="button" className="goUp" onClick={() => scrollToPage(3)}>
          Voltar
        </button> */}
        <div className="question">
          <div className="littleTitle">Finalização</div>
          <span className="highlight">Moldura</span> selecionada
        </div>
        <div className="selection">          
          <div style={{...styles.selectionBox, ...styles.selectedBox}}>
            Sem moldura
          </div>
          <div style={{...styles.selectionBox}} className="disabled">
            Simples
          </div>
          <div style={{...styles.selectionBox, ...styles.removeSpacingBox}} className="disabled">
            Elaborada
          </div>
        </div>
        <div className="description">
          <span className="highlight">Sua seleção: </span> 
          Estamos trabalhando para esta opção ser viável em breve. :) <strong>Fale nos comentários se vocẽ tiver interesse em emoldurar</strong>. <a href="#">Leia mais</a> 
        </div>
        <button type="button" className="goDown" onClick={() => scrollToSelector('#time')}>
          Próximo
        </button>
      </div>

      <h1 className="intervalInterval">Tempo</h1>

      <div id="time" style={blockScreenNavigator()}>
        {/* page 9 */}
        {/* <button type="button" className="goUp" onClick={() => scrollToPage(3)}>
          Voltar
        </button> */}
        <div className="question">
          <div className="localIcon">
            <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
          </div>
          <div className="littleTitle">Tempo</div>
          <span className="highlight">2 semanas + dias de entrega</span>
        </div>
        <div className="selection">
          <div className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
            Nam sed condimentum risus, eu maximus urna. In a vestibulum est. 
            Duis sapien risus, suscipit vel lacus sed, interdum hendrerit metus. 
          </div>
        </div>
        <button type="button" className="goDown" onClick={() => scrollToSelector('#upload')}>
          Próximo
        </button>
      </div>

      <h1 className="intervalInterval">Envio de fotos</h1>

      <div id="upload" style={blockScreenNavigator()}>
        {/* page 10 */}
        {/* <button type="button" className="goUp" onClick={() => scrollToPage(3)}>
          Voltar
        </button> */}
        <div className="question">
          <div className="localIcon">
            <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-camera"><path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path><circle cx="12" cy="13" r="4"></circle></svg>
          </div>
          <div className="littleTitle">Envio</div>
          Selecione <span className="highlight">suas fotos</span>
        </div>
        <div className="selection">          
          <div style={{...styles.selectionBox, width: 90, height: 90 }}>
            1
          </div>
          <div style={{...styles.selectionBox, width: 90, height: 90}}>
            2
          </div>
          <div style={{...styles.selectionBox, width: 90, height: 90}}>
            3
          </div>
          <div style={{...styles.selectionBox, width: 90, height: 90}}>
            4
          </div>
        </div>
        <div className="description">
          3 fotos no mínimo 
        </div>
        <button type="button" className="goDown" onClick={() => scrollToSelector('#comment')}>
          Próximo
        </button>
      </div>

      <div id="comment" style={blockScreenNavigator()}>
        {/* page 11 */}
        {/* <button type="button" className="goUp" onClick={() => scrollToPage(3)}>
          Voltar
        </button> */}
        <div className="question">
          <div className="localIcon">
            <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
          </div>
          <div className="littleTitle">Envio</div>
          Faça seus <span className="highlight">comentários</span> finais
        </div>
        <center>** mostrar aqui as fotos **</center>
        <div className="selection">
          <textarea className="finalComment" maxLength="250"></textarea>
        </div>
        <button type="button" className="goDown" onClick={() => scrollToSelector('#payment')}>
          Próximo
        </button>
      </div>

      <h1 className="intervalInterval">Pagamento</h1>

      <div id="payment" style={blockScreenNavigator()}>
        {/* page 12 */}
        {/* <button type="button" className="goUp" onClick={() => scrollToPage(3)}>
          Voltar
        </button> */}
        <div className="question">
          <div className="localIcon">
            <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
          </div>
          <div className="littleTitle">Pagamento</div>
          Selecione a <span className="highlight">forma de pagamento</span>
        </div>
        <div className="selection">
          <div style={{...styles.selectionBox, ...styles.selectedBox, width: 140}}>
            Cartão<br/>de Crédito
          </div>
          <div style={{...styles.selectionBox, width: 140}}>
            PayPal
          </div>
          <div style={{...styles.selectionBox, width: 140}}>
            PagSeguro
          </div>
          <div style={{...styles.selectionBox, width: 140}}>
            Mercado Pago
          </div>
          <div style={{...styles.selectionBox, width: 140}}>
            PIX
          </div>
          <div style={{...styles.selectionBox, width: 140}}>
            Boleto
          </div>
        </div>
        <div className="description">
          <span className="highlight">Rápido: </span> 
          Cartão de crédito é a segunda opção mais rápida para início da pintura (opção muito rápida é PIX: 10% desconto). <a href="#">Leia mais</a> 
        </div>
        <button type="button" className="goDown" onClick={() => scrollToSelector('#congrats')}>
          Próximo
        </button>
      </div>

      {/* <h1 className="intervalInterval">Parabéns</h1> */}

      <div id="congrats" style={blockScreenNavigator()}>
        {/* page 12 */}
        {/* <button type="button" className="goUp" onClick={() => scrollToPage(3)}>
          Voltar
        </button> */}
        <div className="question">
          <div className="localIcon">
          <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
          </div>
          <div className="littleTitle">Pronto!</div>
          Parabéns! Você receberá sua pintura em breve!
        </div>
        <div className="description">
        </div>
        <button type="button" className="goDown" onClick={() => scrollToPage(13)}>
          Ir para o painel
        </button>
      </div>

    </div>
  );
}

export default App;

const styles = {
  removeSpacingBox: {
    marginRight: 0,
  },
  selectionBox: {

    color: '#444444',
    border: '1px solid #d4d4d4',

    width: 160, 
    height: 160, 
    borderRadius: 10, 
    backgroundColor: '#e7e5df', 
    cursor: 'pointer', 
    float: 'left',

    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center', 

    marginRight: 10, 
  },
  selectedBox: {
    border: '5px solid #756c61',
    backgroundColor: '#756c61CC', 
    color: '#ffffff'
  }
}